import { render, staticRenderFns } from "./bankFieldItem.vue?vue&type=template&id=cf51da8c&"
import script from "./bankFieldItem.vue?vue&type=script&lang=js&"
export * from "./bankFieldItem.vue?vue&type=script&lang=js&"
import style0 from "./bankFieldItem.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports