<template>
  <div class="bank-field-item">
    <BOverlay :show="isBuzy">
      <div class="bank-field-item__label">
        {{ t(`bank.fields.${fieldName}.label`) }}:
      </div>
      <div class="bank-field-item__input">
        <EditInput
          :model="value"
          :toggle-only-by-icon="false"
          :readonly="readonly"
          @input="update($event)"
        >
          {{ value || t(`bank.fields.${fieldName}.placeholder`) }}
        </EditInput>
      </div>
    </BOverlay>
  </div>
</template>

<script>
import {
  ref, toRefs, watch,
} from '@vue/composition-api';
import { BOverlay } from 'bootstrap-vue';
import { useI18n } from '@/hooks/useI18n';
import EditInput from '../editInput/EditInput.vue';

export default {
  components: { EditInput, BOverlay },
  props: {
    value: {
      type: String,
      default: '',
    },
    fieldName: {
      type: String,
      default: '',
    },
    forceUpdate: {
      type: Number,
      default: 0,
    },
    readonly: Boolean,
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const { fieldName, value, forceUpdate } = toRefs(props);

    const isBuzy = ref(false);

    const update = (updatedValue) => {
      isBuzy.value = true;
      emit('update', { field: fieldName.value, value: updatedValue });
    };

    watch([value, forceUpdate], () => {
      isBuzy.value = false;
    });

    return {
      t,
      update,
      isBuzy,
    };
  },
};
</script>

<style lang="sass">
.bank-field-item
  &__label
    flex: 0
    white-space: nowrap
    font-weight: 500
  &__input
    flex: 1
    overflow: hidden
  .b-overlay
    &-wrap
      @media (min-width: 1440px)
        display: flex
        gap: 8px
        align-items: center
    .bg-light
      transform: scale3d(1.05, 1.2, 1)
</style>
