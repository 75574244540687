<template>
  <b-card
    class="client-props-card"
    no-body
  >
    <AppCollapse>
      <AppCollapseItem
        title=""
        :is-visible.sync="isContactsDropdownVisible"
        class="collapse-no-body"
        no-body
      >
        <template #header>
          <div class="d-flex w-100 align-items-center justify-content-between pl-1 pt-1 pb-1 pr-1">
            <h4 class="m-0">
              Контакты
            </h4>
            <FeatherIcon
              v-if="$store.getters['user/permission']('clients:update')"
              id="addClientIcon"
              icon="PlusIcon"
              size="24"
              @click.stop="addContact"
            />
          </div>
        </template>
        <Contacts
          :contacts="item.contacts"
          entity-name="client"
          :entity-id="item.id"
          :add-contact.sync="newContactVisible"
          @needToUpdate="$emit('needToUpdate')"
        />
      </AppCollapseItem>

      <AppCollapseItem
        :is-visible="isNew"
        title=""
        class="collapse-no-body"
        no-body
      >
        <template #header>
          <div class="d-flex w-100 align-items-center justify-content-between pl-1 pt-1 pb-1 pr-1">
            <h4 class="m-0">
              Реквизиты
            </h4>
          </div>
        </template>
        <BankDetailsFields
          style="margin: 0 28px 28px 28px"
          :entity-id="item.id"
          entity-name="clients"
        />
      </AppCollapseItem>
    </AppCollapse>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue';
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
import { ref } from '@vue/composition-api';
import BankDetailsFields from './bankDetailsFields.vue';
import Contacts from '../contacts/Contacts.vue';

export default {
  components: {
    BCard,
    AppCollapse,
    AppCollapseItem,
    BankDetailsFields,
    Contacts,
  },
  props: {
    isNew: Boolean,
    item: Object,
  },
  setup() {
    const isContactsDropdownVisible = ref(true);
    const newContactVisible = ref(false);
    const addContact = () => {
      newContactVisible.value = true;
      isContactsDropdownVisible.value = true;
    };

    return {
      isContactsDropdownVisible,
      newContactVisible,
      addContact,
    };
  },
};
</script>

<style lang="scss">
@import '~@/assets/scss/utils';

@include dark-theme {
  .client-props-card {
    &__contact-item {
      &:not(:first-child) {
        border-top: 1px solid $theme-dark-input-border-color;
      }
    }
  }
}

.client-props-card {
  .collapse-icon [data-toggle='collapse']:after {
    background-size: 24px;
  }
  &__contact-item {
    padding-top: 20px;
    &:not(:first-child) {
      margin-top: 20px;
      border-top: 1px solid $border-color;
    }
  }
  .client-prop-bank-field__label {
    flex: 1;
    max-width: fit-content;
    width: 100%;
  }
  .client-prop-bank-field__input {
    flex: 1;
  }
}
</style>
