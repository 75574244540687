<template>
  <BCard no-body>
    <h4
        :class="'ml-2 mt-2 ' + (isMobile ? '' : 'mb-3')"
    >
      Объекты
    </h4>
    <FacilitiesList
      :by-client-id="clientId"
      no-cache
      :readonly="!$store.getters['user/permission']('clients:update')"
    />
  </BCard>
</template>

<script>
import { BCard } from 'bootstrap-vue';
import FacilitiesList from '@/views/facilities/list/FacilitiesList.vue';
import {ref} from "@vue/composition-api";
import {useStore} from "@/hooks/useStore";

export default {
  components: {
    BCard,
    FacilitiesList,
  },
  props: {
    clientId: Number,
  },
  setup() {
    const isMobile = ref(false);
    const store = useStore();

    isMobile.value = store.state.app.isMobile;

    return {
      isMobile
    };
  },
};
</script>
