<template>
  <div class="bank-details">
    <div class="bank-details-fields">
      <BankFieldItem
        v-for="field in bankFields"
        :key="field"
        :value="bankDetails[field]"
        :field-name="field"
        :force-update="forceUpdate"
        :style="{
          maxWidth: bankFieldsParams.size[field] === 2 && '100% '
        }"
        :readonly="!$store.getters['user/permission']('clients:update')"
        @update="update"
      />
    </div>
  </div>
</template>

<script>
import { ref, toRefs } from '@vue/composition-api';
import { useStore } from '@/hooks/useStore';
import { useToast } from '@/hooks/useToast';
import { useI18n } from '@/hooks/useI18n';
import BankFieldItem from './bankFieldItem.vue';

export default {
  components: { BankFieldItem },
  props: {
    entityName: {
      type: String,
      defauls: 'clients',
    },
    entityId: {
      type: [Number, String],
      default: null,
    },
  },
  setup(props) {
    const { entityName, entityId } = toRefs(props);
    const { t } = useI18n();
    const { successToast, dangerToast } = useToast();

    const bankDetails = ref({});
    const bankFields = [
      'full_name',
      'fact_address',
      'inn',
      'bank_bik',
      'ogrn',
      'bank_name',
      'kpp',
      'bank_account',
      'ceo',
      'bank_corr',
    ];
    const bankFieldsParams = {
      size: {
        full_name: 2,
        fact_address: 2,
      },
    };

    const store = useStore();
    const forceUpdate = ref(0);
    const getBankDetails = async () => {
      const { result, data } = await store.dispatch(`${entityName.value}/getBankDetails`, entityId.value);
      if (result) {
        bankDetails.value = data;
      }
      forceUpdate.value = Date.now();
    };

    const updateBankDetails = async (newBankDetails) => {
      const response = await store.dispatch(`${entityName.value}/updateClientBankDetails`, {
        id: entityId.value,
        model: newBankDetails,
      });
      if (response.result) {
        getBankDetails();
        successToast(
          t('settings.messageUpdated.title'),
          t('settings.messageUpdated.text'),
        );
      } else {
        const errorNames = Object.keys(response.data.errors);
        const firstError = response.data.errors[errorNames[0]];
        dangerToast(
          response.data.message,
          firstError[0],
        );
        forceUpdate.value = Date.now();
      }
    };
    getBankDetails();

    const update = ({ field, value }) => {
      // emit('updateField', { field, value });
      const updatedField = {};
      updatedField[field] = value;
      const newBankDetails = { ...bankDetails.value, ...updatedField };
      updateBankDetails(newBankDetails);
    };

    return {
      bankFields,
      bankFieldsParams,
      bankDetails,
      update,
      forceUpdate,
    };
  },
};
</script>

<style lang="sass">
.bank-details
  .bank-details-fields
    display: flex
    flex-wrap: wrap
    gap: 12px
    @media (max-width: 1199px)
      flex-direction: column
    .bank-field-item
      width: 100%
      @media (min-width: 1200px)
        flex: 1 1 50%
        max-width: calc(50% - 6px)
</style>
