<template>
  <div>
    <div
      v-if="isLoaderVisible"
      class="d-flex justify-content-center"
    >
      <BSpinner />
    </div>

    <template v-else-if="clientData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          md="6"
          xl="6"
          lg="6"
        >
          <client-info-card @updated="getClient" />
            <b-card>
                <b-tabs
                        lazy
                        fill
                >
                    <b-tab>
                        <template #title>
                            Клиент
                        </template>
                        <ClientFileUploadCard
                                is-editable
                                :items="files"
                                :payload="clientFilesPagination"
                                :file-types="fileTypes"
                                @get="getClientFiles"
                                @submit="addFiles"
                                @delete="handleDeleteFile"
                        />
                    </b-tab>
                    <b-tab>
                        <template #title>
                            Объекты
                        </template>
                        <ClientFileUploadCard
                                :items="facilityFiles"
                                :payload="facilityFilesPagination"
                                :file-types="fileTypes"
                                @get="getFacilityFiles"
                        />
                    </b-tab>
                </b-tabs>
            </b-card>
        </b-col>
        <b-col
          cols="12"
          md="6"
          xl="6"
          lg="6"
        >
          <client-props-card
            v-if="clientData"
            :item="clientData"
            :is-new="false"
            @needToUpdate="needToUpdate"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
        >
          <client-objects-card :client-id="clientData.id" />
        </b-col>
      </b-row>
    </template>
    <InteractionsSidebar />
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BSpinner,
  BTabs,
  BTab,
  BCard
} from 'bootstrap-vue';
import {
  provide, ref, watch, nextTick, toRefs, onBeforeUnmount, computed,
} from '@vue/composition-api';
import { useRouter } from '@core/utils/utils';
import ClientInfoCard from '@/components/clientInfoCard/ClientInfoCard.vue';
import ClientObjectsCard from '@/views/clients/view/cards/ClientObjectsCard.vue';
import ClientPropsCard from '@/components/clientPropsCard/ClientPropsCard.vue';
import InteractionsSidebar from '@/components/interactions/InteractionsSidebar.vue';
import { useI18n } from '@/hooks/useI18n';
import { useStore } from '@/hooks/useStore';
import { useToast } from '@/hooks/useToast';
import { getRandomString } from '@/utils/stringHelpers';
import { useClient } from '@/hooks/useClient';
import {axiosIns} from "@/store/api";

export default {
  name: 'ClientView',
  components: {
    BRow,
    BCol,
    BSpinner,
    BTabs,
    BTab,
    BCard,

    InteractionsSidebar,
    ClientInfoCard,
    ClientObjectsCard,
    ClientPropsCard,
    ClientFileUploadCard: () => import('@/components/requests/FileUploadCard.vue'),
  },
  props: {
    clientId: [Number, String],
  },
  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const { successToast, dangerToast } = useToast();
    const { router } = useRouter();
    const clientFiles = ref({});
    const clientFilesPagination = ref({
        page: 1,
        perPage: 5
    });
    const facilityFiles = ref({});
    const facilityFilesPagination = ref({
        page: 1,
        perPage: 5
    })

    provide('mapMarkerIcon', 'object');

    const {
      getEmptyBankDetails,
    } = useClient();

    const { clientId } = toRefs(props);

      const fileTypes = ref(null);
      const getDictionaries = async () => {
          const result = await store.dispatch('dictionaries/getDictionaries', {
              dictionaries: 'file_types'
          });

          if (result) {
              fileTypes.value = store.state.dictionaries.list.file_types
                  .map((el) => ({text: el.name, value: el.id, disabled: false}));
          }
      };

      getDictionaries();

    // Client contacts
    const contacts = ref([]);
    const newContacts = ref([]);
    const computedContacts = computed(() => [
      ...contacts.value,
      ...newContacts.value,
    ]);
    provide('contacts', computedContacts);
    const isContactUpdating = ref(false);
    const onContactUpdated = async (contactId) => {
      if (isContactUpdating.value) return;
      const contact = computedContacts.value.find((contact) => contact.id === contactId);
      if (!contact || contact.isNew) return;

      isContactUpdating.value = true;
      const response = await store.dispatch('contacts/updateClientContact', {
        contactId: contact.id,
        contactModel: contact,
      });
      isContactUpdating.value = false;

      if (response.result) {
        successToast(
          t('settings.messageUpdated.title'),
          t('settings.messageUpdated.text'),
        );
      } else if (response.data.errors) {
        const { errors } = response.data;
        const firstErrorKey = Object.keys(errors)[0];
        const error = errors[firstErrorKey][0];
        dangerToast(
          response.data.message,
          error,
        );
      }
    };

    const addNewContact = (contact) => {
      newContacts.value.push({
        ...contact,
        isNew: true,
        id: getRandomString(),
      });
    };

    // Client bank details
    const bankDetails = ref(null);
    provide('bankDetails', bankDetails);
    const updateClientBankDetails = async (newBankDetails) => {
      const { result } = await store.dispatch('clients/updateClientBankDetails', {
        clientId: clientId.value,
        model: newBankDetails,
      });

      if (result) {
        successToast(
          t('settings.messageUpdated.title'),
          t('settings.messageUpdated.text'),
        );
        return;
      }
      dangerToast(
        t('settings.updateFailedMessage.title'),
        t('settings.updateFailedMessage.text'),
      );
    };
    provide('updateBankDetails', updateClientBankDetails);

    const getBankDetails = async () => {
      const emptyBankDetails = getEmptyBankDetails();
      const { result, data } = await store.dispatch('clients/getBankDetails', clientId.value);

      if (result) {
        bankDetails.value = Object.entries(data).reduce((acc, detail) => ({
          ...acc,
          ...(detail[0] in emptyBankDetails ? {
            [detail[0]]: detail[1],
          } : {}),
        }), {});
      }
    };
    provide('getBankDetails', getBankDetails);

    // Client data
    const serverData = ref(null);
    const isProcessing = ref(false);
    provide('isProcessing', isProcessing);
    const clientData = ref(null);
    const errors = ref([]);
    provide('errors', errors);
    provide('clientData', clientData);

    const isFetchingData = ref(null);
    const isLoaderVisible = ref(false);

    const getClientFiles = (payload) => {
        store.dispatch('clients/getFiles', { id: Number(clientId.value), payload }).then((res) => {
            clientFiles.value = res.data;
            clientFilesPagination.value = {
                page: res.data.current_page,
                perPage: res.data.per_page,
                total: res.data.total
            }
        });
    }

    const getFacilityFiles = (payload) => {
        store.dispatch('clients/getFacilityFiles', { id: Number(clientId.value), payload }).then((res) => {
            facilityFiles.value = res.data;
            facilityFilesPagination.value = {
                page: res.data.current_page,
                perPage: res.data.per_page,
                total: res.data.total
            }
        });
    }

    const getClient = async (showLoader = false) => {
      if (isFetchingData.value) return;

      isFetchingData.value = true;
      isLoaderVisible.value = showLoader;

      await getClientFiles(clientFilesPagination.value);
      await getFacilityFiles(facilityFilesPagination.value);

      const { result, data } = await store.dispatch('clients/getClient', clientId.value);
      isFetchingData.value = false;
      isLoaderVisible.value = false;

      if (result) {
        serverData.value = JSON.parse(JSON.stringify(data));
        clientData.value = data;
        contacts.value = JSON.parse(JSON.stringify(clientData.value.contacts));
      }
    };
    provide('getClient', getClient);

    const saveContact = async (contactId) => {
      const response = await store.dispatch('contacts/addContactsToClient', {
        clientId: clientId.value,
        contactModel: newContacts.value.find((contact) => contact.id === contactId),
      });

      if (response.result) {
        await getClient();
        newContacts.value = newContacts.value.filter((contact) => contact.id !== contactId);
      } else if (response.data.errors) {
        const { errors } = response.data;
        const firstErrorKey = Object.keys(errors)[0];
        const error = errors[firstErrorKey][0];
        dangerToast(
          response.data.message,
          error,
        );
      }
    };

    const removeContact = async (contactId) => {
      if (isProcessing.value) return;

      const contact = computedContacts.value.find((contact) => contact.id === contactId);
      if (!contact) return;
      if (contact.isNew) {
        newContacts.value = newContacts.value.filter((contact) => contact.id !== contactId);
        isProcessing.value = false;
        return;
      }

      isProcessing.value = true;
      const { result } = await store.dispatch('contacts/removeClientContact', {
        clientId: clientId.value,
        contactId,
      });
      isProcessing.value = false;

      if (result) {
        await getClient();
        newContacts.value = newContacts.value.filter((contact) => contact.id !== contactId);
      }
    };

    // Client interaction
    const interactionList = ref([]);
    provide('interactionList', interactionList);
    const isInteractionListLoading = ref(false);
    provide('isInteractionListLoading', isInteractionListLoading);
    isInteractionListLoading.value = true;
    const getClientInteraction = async () => {
      isInteractionListLoading.value = true;
      const { result, data } = await store.dispatch('clients/getClientInteraction', props.clientId);
      isInteractionListLoading.value = false;

      if (result) {
        interactionList.value = data;
      }
    };
    provide('getClientInteraction', getClientInteraction);

    // const getClientInteractionUnreadCount = async () => {
    //   await store.dispatch('clients/getClientInteractionUnreadCount', props.clientId);
    // };

    const updateClientData = async () => {
      const { result, data } = await store.dispatch('clients/updateClient', {
        clientId: props.clientId,
        model: clientData.value,
      });

      if (result) {
        successToast(
          t('settings.messageUpdated.title'),
          t('settings.messageUpdated.text'),
        );
        return data;
      }
      dangerToast(
        t('settings.updateFailedMessage.title'),
        t('settings.updateFailedMessage.text'),
      );
      return {};
    };
    watch(clientData, async (val, oldVal) => {
      if (JSON.stringify(val) === JSON.stringify(serverData.value)) return;
      if (!oldVal || isProcessing.value) return;
      isProcessing.value = true;
      const newData = await updateClientData();
      clientData.value = {};
      clientData.value = newData;
      serverData.value = JSON.parse(JSON.stringify(newData));
      isProcessing.value = false;
    }, { deep: true });

    const clientLoaded = ref(false);
    const isBankDetailsUpdating = ref(false);
    const onBankDetailsUpdated = async () => {
      if (!bankDetails.value || isBankDetailsUpdating.value || !clientLoaded.value) return;
      isBankDetailsUpdating.value = true;
      try {
        await updateClientBankDetails();
        await getBankDetails();
      } catch (err) {
        //
      } finally {
        isBankDetailsUpdating.value = false;
      }
    };

    watch(clientId, () => {
      nextTick(() => {
        clientLoaded.value = false;
        getClient(true);
        getBankDetails();
        // getClientInteractionUnreadCount();
        clientLoaded.value = true;
      });
    }, {
      immediate: true,
    });

    onBeforeUnmount(() => {
      store.dispatch('clients/clearInteractionUnreadCount');
    });

    const deleteClient = async () => {
      isProcessing.value = true;
      const { result } = await store.dispatch('clients/deleteClient', {
        clientId: clientId.value,
      });
      isProcessing.value = false;

      if (result) {
        router.replace('/clients');
      }
    };
    provide('deleteClient', deleteClient);

    const needToUpdate = async (silent = true) => {
      await getClient(!silent);
      if (!silent) {
        successToast(
          t('settings.messageUpdated.title'),
          t('settings.messageUpdated.text'),
        );
      }
    };

      const addFiles = async (filesArray) => {
        const formData = new FormData();

        filesArray.forEach((file) => {
            formData.append('files[]', file);
        });

        await axiosIns.post('tools/upload-files', formData)
            .then((res) => {
                if (res.status === 422) {
                    dangerToast(t('filesUpload.uploadSuccessTitle'), res.data.message ?? t("filesUpload.uploadErrorText"))
                } else {
                    const { files } = res.data;

                    store.dispatch('clients/addFiles', { id: Number(clientId.value), files: files.map((file) => file.id) }).then(() => {
                        successToast(t('filesUpload.uploadSuccessTitle'), t('filesUpload.uploadSuccessText'));
                    }).then(() => {
                        store.dispatch('clients/getFiles', { id: Number(clientId.value), payload: clientFilesPagination.value }).then((res) => {
                            clientFiles.value = res.data;
                            clientFilesPagination.value = {
                                page: res.data.current_page,
                                perPage: res.data.per_page,
                                total: res.data.total
                            }
                        });
                    }).catch(() => {
                        dangerToast(t('file_upload.message.error'));
                    });
                }
            })
            .catch(() => {
                dangerToast(t('file_upload.message.error'));
            });
      }

      const handleDeleteFile = (fileId) => {
          if (fileId) {
              store.dispatch('clients/deleteFile', { clientId: Number(clientId.value), fileId, payload: clientFilesPagination.value }).then((res) => {
                  if (!res.data.data.length && res.data.current_page > 1) {
                      store.dispatch('clients/getFiles', { id: Number(clientId.value), payload: { ...clientFilesPagination.value, page: clientFilesPagination.value - 1 } }).then((res) => {
                          clientFiles.value = res.data;
                          clientFilesPagination.value = {
                              page: res.data.current_page,
                              perPage: res.data.per_page,
                              total: res.data.total
                          }
                      });
                  } else {
                      clientFiles.value = res.data;
                      clientFilesPagination.value = {
                          page: res.data.current_page,
                          perPage: res.data.per_page,
                          total: res.data.total
                      }
                  }
                  successToast(t('filesUpload.fileSuccessDeleteTitle'), t('filesUpload.fileSuccessDeleteText'))
              })
          }
      }

    return {
      t,

      isProcessing,
      onContactUpdated,
      clientData,

      onBankDetailsUpdated,

      addNewContact,
      saveContact,
      removeContact,

      isLoaderVisible,
      getClient,
      needToUpdate,
      addFiles,

      getClientFiles,
      getFacilityFiles,
      handleDeleteFile,

      files: clientFiles,
      clientFilesPagination,
      facilityFiles,
      facilityFilesPagination,
        fileTypes,
    };
  },
};
</script>

<style lang="scss"></style>
